/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #303872;
}

/* Handle on hover */

.cursor-pointer {
  cursor: pointer;
}

td div {
  font-size: 12px !important;
}
th {
  font-size: 14px !important;
}
p {
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.main-buttons {
  height: 9rem;
  width: 7rem;
}

.form-multi-select-tag {
  border: 1px solid #b1b7c1b1;
  padding: 0.2rem;
  background: #bfc2c747;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.form-multi-select-search {
  min-width: 20rem;
  border: 1px solid #b1b7c1;
  border-radius: 5px;
  padding: 0.4rem;
  outline: none;
}

.form-multi-select-option {
  margin-right: 0.5rem;
  padding: 10px;
}

.form-multi-select-optgroup-label {
  font-weight: 600;
  background: #e4e4e465;
  padding: 0.5rem;

  text-transform: capitalize;
}
